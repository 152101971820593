<template>
  <div class="coming-soon">
    <div class="background-animation">
      <div class="mountain"></div>
      <div class="clouds"></div>
    </div>
    <div class="hiking-boot">
      <img src="/hiking-boot-icon.png" alt="Hiking Boot" class="boot" />
    </div>
    <h1>Ando Hikeando</h1>
    <p>Estamos en construcción...</p>
  </div>
</template>

<style scoped>
/* Fondo general */
.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, #6a9dab, #3c6e71);
}

/* Animación de fondo de montañas */
.background-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.mountain {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: url('https://example.com/mountain.svg') no-repeat center bottom;
  background-size: cover;
  animation: mountainMove 10s linear infinite;
}

/* Nubes flotantes */
.clouds {
  position: absolute;
  top: 20%;
  width: 100%;
  height: 20%;
  background: url('https://example.com/clouds.svg') repeat-x;
  background-size: contain;
  opacity: 0.8;
  animation: cloudsMove 30s linear infinite;
}

.hiking-boot {
  animation: bounce 3s infinite ease-in-out;
  margin-bottom: 20px;
}

.boot {
  width: 120px; /* Ajusta el tamaño de la imagen */
  height: auto;
}

/* Animación de rebote */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Animación de movimiento de montañas */
@keyframes mountainMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Animación de movimiento de nubes */
@keyframes cloudsMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

h1 {
  font-size: 2.5rem;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

p {
  font-size: 1.2rem;
  color: #e0f7fa;
  animation: fadeIn 3s ease-in-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

/* Animación de aparición de texto */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
